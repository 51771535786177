import React, { useEffect, useState } from 'react'
import NewsItem from './NewsItem';

const NewsBoard = ({ category, country }) => {

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        // let url = `https://newsapi.org/v2/top-headlines?country=${country}&apiKey=9ecce800baed4e9da302d4a60a2fa180&category=${category}`;
        let url = `https://saurav.tech/NewsAPI/top-headlines/category/${category}/${country}.json`;
        fetch(url).then((res) => res.json()).then(((data) => setArticles(data.articles)));
    }, [category, country])

    return (
        <div className='mt-3'>
            <h2 className='text-center'>Latest <span className='badge bg-danger'>News</span></h2>
            <div className='row'>
                {articles && articles.map((news, i) => (
                    <div key={i} className='col'>
                        <NewsItem title={news.title} description={news.description} src={news.urlToImage} url={news.url} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NewsBoard