import React from 'react'
import dummyNews from "../assets/news.jpeg";

const NewsItem = ({title, description, src, url }) => {
    return (
        <div>
            <div className="card bg-dark text-light mb-3 d-inline-block my-3 mx-3 px-2 py-2" style={{ maxWidth: "360px" }}>
                <img src={src || dummyNews} className="card-img-top" alt={title} style={{ height: "200px", width: "345px" }} />
                <div className="card-body">
                    <h5 className="card-title">{title.slice(0,100)}</h5>
                    <p className="card-text">{description ? description.slice(0,200) : "Currently, no description is available for this news. Follow for forthcoming details on this evolving story. Your source for the latest updates..."}</p>
                    <a href={url} target='_blank' rel="noreferrer" className="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>
    )
}

export default NewsItem