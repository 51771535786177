import React from 'react'

const Navbar = ({ setCategory, setCountry, currentCountry }) => {
    const countriesList = [
        { code: "ae", name: "United Arab Emirates" },
        { code: "ar", name: "Argentina" },
        { code: "at", name: "Austria" },
        { code: "au", name: "Australia" },
        { code: "be", name: "Belgium" },
        { code: "bg", name: "Bulgaria" },
        { code: "br", name: "Brazil" },
        { code: "ca", name: "Canada" },
        { code: "ch", name: "Switzerland" },
        { code: "cn", name: "China" },
        { code: "co", name: "Colombia" },
        { code: "cu", name: "Cuba" },
        { code: "cz", name: "Czech Republic" },
        { code: "de", name: "Germany" },
        { code: "eg", name: "Egypt" },
        { code: "fr", name: "France" },
        { code: "gb", name: "United Kingdom" },
        { code: "gr", name: "Greece" },
        { code: "hk", name: "Hong Kong" },
        { code: "hu", name: "Hungary" },
        { code: "id", name: "Indonesia" },
        { code: "ie", name: "Ireland" },
        { code: "il", name: "Israel" },
        { code: "in", name: "India" },
        { code: "it", name: "Italy" },
        { code: "jp", name: "Japan" },
        { code: "kr", name: "South Korea" },
        { code: "lt", name: "Lithuania" },
        { code: "lv", name: "Latvia" },
        { code: "ma", name: "Morocco" },
        { code: "mx", name: "Mexico" },
        { code: "my", name: "Malaysia" },
        { code: "ng", name: "Nigeria" },
        { code: "nl", name: "Netherlands" },
        { code: "no", name: "Norway" },
        { code: "nz", name: "New Zealand" },
        { code: "ph", name: "Philippines" },
        { code: "pl", name: "Poland" },
        { code: "pt", name: "Portugal" },
        { code: "ro", name: "Romania" },
        { code: "rs", name: "Serbia" },
        { code: "ru", name: "Russia" },
        { code: "sa", name: "Saudi Arabia" },
        { code: "se", name: "Sweden" },
        { code: "sg", name: "Singapore" },
        { code: "si", name: "Slovenia" },
        { code: "sk", name: "Slovakia" },
        { code: "th", name: "Thailand" },
        { code: "tr", name: "Turkey" },
        { code: "tw", name: "Taiwan" },
        { code: "ua", name: "Ukraine" },
        { code: "us", name: "United States" },
        { code: "ve", name: "Venezuela" },
        { code: "za", name: "South Africa" }
    ];

    const categories = [
        { code: "general", name: "General" },
        { code: "business", name: "Business" },
        { code: "entertainment", name: "Entertainment" },
        { code: "health", name: "Health" },
        { code: "science", name: "Science" },
        { code: "sports", name: "Sports" },
        { code: "technology", name: "Technology" },
    ];

    function findCountryName(countryCode) {
        const country = countriesList.find(country => country.code === countryCode.toLowerCase());

        if (country) {
            return country.name;
        } else {
            return "Country not found";
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#/"><span className='badge bg-light text-dark fs-4'>NewsMag</span></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            {categories.map((category, i) => (
                                <div className="nav-link" role="button" onClick={() => setCategory(category.code)} >{category.name}</div>
                            ))}
                            <div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
                                <ul class="navbar-nav">
                                    <li class="nav-item dropdown">
                                        <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            {findCountryName(currentCountry)}
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-dark">
                                            {countriesList.slice().sort((a, b) => a.name.localeCompare(b.name)).map((country, i) => (
                                                <li key={i}><div class="dropdown-item" role='button' onClick={() => setCountry(country.code)} >{country.name}</div></li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar